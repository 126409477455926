import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useActions, useGetters } from 'vuex-composition-helpers';
export function useFeed() {
    const { get: getFeedFromStore } = useGetters('feed', ['get']);
    const { get: fetchFeedInfo } = useActions('feed', ['get']);
    const feedId = useRoute().params.id;
    const feed = computed(() => {
        if (!feedId)
            return null;
        return getFeedFromStore.value(feedId);
    });
    onMounted(() => {
        fetchFeedInfo(feedId);
    });
    return { feed };
}
